import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import './App.scss'
import '../i18n'
import {Routes} from './routing/Routes'
import AuthInit from './modules/auth/redux/AuthInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import SubscriptionsContext from '../context/SubscriptionsContext'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter5Adapter} from 'use-query-params/adapters/react-router-5'
import qs from 'query-string'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: qs.parse,
            objectToSearchString: qs.stringify,
          }}
        >
          <LayoutProvider>
            <AuthInit>
              <SubscriptionsContext>
                <Routes />
              </SubscriptionsContext>
            </AuthInit>
          </LayoutProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
