export const REACT_APP_BASE_LAYOUT_CONFIG_KEY = 'metronic-react'
// export const BACKEND_API_URL = 'http://localhost:5000/api'
// export const BACKEND_UPLOAD_URL = 'http://localhost:5000/'

export const DEFAULTTOKEN = 'token7544f24ca9e20f60901116776e4eb324'
export const S3_BUCKET = 'infinity-backend'
export const REGION = 'eu-west-3'
export const ACCESS_KEY = 'AKIA5YMHI3YUEP4UPWER'
export const SECRET_ACCESS_KEY = '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'
// export const BACKEND_API_URL = 'https://4l50m5mbig.execute-api.eu-west-3.amazonaws.com/api' // Live Instance
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL // Test Instnace
export const BACKEND_UPLOAD_URL = 'https://infinity-backend.s3.eu-west-3.amazonaws.com/'
export const BACKEND_API_V2 = process.env.REACT_APP_BACKEND_API_V2
export const REACT_APP_BACKEND_API_V1_1 = process.env.REACT_APP_BACKEND_API_V1_1
export const REACT_APP_BACKEND_API_V1_2 = process.env.REACT_APP_BACKEND_API_V1_1

export const removeAllSession = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('id')
  localStorage.removeItem('email')
  localStorage.removeItem('firstName')
  localStorage.removeItem('secondName')
  localStorage.removeItem('birth')
  localStorage.removeItem('streetaddress')
  localStorage.removeItem('suite')
  localStorage.removeItem('postalcode')
  localStorage.removeItem('cityaddress')
  localStorage.removeItem('country')
  localStorage.removeItem('timezone')
  localStorage.removeItem('photo_url')
  localStorage.removeItem('security_flag')
  localStorage.removeItem('restriction_flag')
  localStorage.removeItem('confirm_flag')
  localStorage.removeItem('cryptowallet')
  localStorage.removeItem('data')
  localStorage.removeItem('hashUserFlag')
}
